<template>
  <div>
    <b-modal v-model="modal.products" :title=" $t('dashboard.discounts.types.productsDiscount') " content-class="card form-rounded-inputs card-ecart"
      header-class="card-header" cancel-variant="none" size="xl" ok-variant="success" @hidden="resetDiscount()"
      :ok-title="$t('general.button.create')" @ok.prevent="fnValidateDiscount" centered>


      <v-observer tag="form" class=" animated fadeIn" ref="formProductsDiscount" @submit.prevent="fnValidateDiscount">
        <div class="row">
          <div class="col-12 col-lg-6">
            <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.store') ">
              <div class="form-group ">
                <label>
                  <span v-text="$t('general.form.store')"></span><br>
                  <small class="text-muted" v-text="$t('informational.form.shippingDiscount.store')"></small>
                </label>
                <div class="input-group ">
                  <select class="custom-select" :class=" { 'is-invalid ':errors.length > 0 } "
                    v-model="newDiscount.storeId">
                    <option :value="null" selected v-text=" $t('general.form.select') ">
                    </option>
                    <option :value="store.id" v-for=" (store,index) in storesList " :key="index"
                      v-text=" store.name || ($t('messages.storeWhitoutName') + ' - ' + (store.id || store.search_store_id) )">
                    </option>
                  </select>
                  <div class="input-group-append">
                    <label class="input-group-text"> <i class="fa "
                        :class=" loading.stores ? 'fa-spinner fa-spin':'fa-store' "></i> </label>
                  </div>
                </div>
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </div>
            </v-validation>
          </div>
          <div class="col-12 col-lg-6 ">
            <v-validation rules="required|min:3|max:50" v-slot="{ errors }" :name=" $t('general.form.name') ">
              <div class="form-group ">
                <label>
                  <span v-text="$t('general.form.name')"></span><br>
                  <small class="text-muted" v-text="$t('informational.form.coupon.name')"></small>
                </label>
                <div class="input-group">
                  <input type="text" class="form-control" v-model="newDiscount.name"
                    placeholder="Campaña Nov / Descuento 1" :class=" { 'is-invalid':(errors.length > 0 ) } ">
                  <div class="input-group-append">
                    <span class="input-group-text ">
                      <i class="fa fa-keyboard "></i>
                    </span>
                  </div>
                  <div class="invalid-feedback" v-if="errors.length > 0 ">
                    {{ errors[0] }}
                  </div>
                </div>
              </div>
            </v-validation>
          </div>
        </div>
        <hr class="mt-1">

        <div class="row justify-content-end ">
          <div class="col-12 col-lg-6 ">
            <div class="form-group ">
              <label>
                <span v-text=" $t('general.form.discount') "> </span>
                <br>
                <small class="text-muted" v-text="$t('informational.form.discount')"></small>
              </label>
              <v-validation rules="required|min_value:5" v-slot="{ errors }" :name=" $t('general.form.discount') ">
                <div class="input-group">
                  <input type="number" class="form-control" v-model="newDiscount.amount" placeholder="100%" max="100"
                    step="1" :class=" { 'is-invalid': (errors.length > 0 ) } ">
                  <div class="input-group-append">
                    <span class="input-group-text ">
                      <i class="fa fa-percentage "></i>
                    </span>
                  </div>
                  <div class="invalid-feedback" v-if="errors.length > 0 ">
                    {{ errors[0] }}
                  </div>
                </div>
              </v-validation>
            </div>
          </div>

          <div class="col-12 col-lg-6 ">
            <div class="form-group">
              <label class="w-100">
                <div class="row">
                  <div class="col">
                    <span v-text=" $t('general.form.availability') "> </span>
                  </div>
                  <div class="col-auto ">
                    <div class="form-check ">
                      <input type="checkbox" class="form-check-input" id="isUnlimited"
                        v-model="newDiscount.isUnlimited">
                      <label class="form-check-label" for="isUnlimited" v-text="$t('general.form.unlimited')"> </label>
                    </div>
                  </div>
                </div>
                <small class="text-muted" v-text="$t('informational.form.productsDiscount.availability')"></small>
              </label>
              <v-validation :rules=" newDiscount.isUnlimited ? '' : 'required|min_value:1|max_value:500' " v-slot="{ errors }"
                :name=" $t('general.form.limitOfUse') ">
                <div class="input-group">
                  <input type="number" class="form-control" v-model="newDiscount.available" placeholder="100"
                    :class=" { 'is-invalid':(errors.length > 0 ) } " :disabled="newDiscount.isUnlimited">
                  <div class="input-group-append">
                    <span class="input-group-text ">
                      <i class="fa " :class=" newDiscount.isUnlimited ? 'fa-infinity':'fa-hashtag' "></i>
                    </span>
                  </div>
                  <div class="invalid-feedback" v-if="errors.length > 0 ">
                    {{ errors[0] }}
                  </div>
                </div>
              </v-validation>
            </div>
          </div>
        </div>
        <hr class="mt-1">

        <div class="row justify-content-end">
          <div class="col-12 col-lg-6">
            <v-validation rules="required" v-slot="{ errors }" :name=" $t('messages.allProducts') ">
              <div class="form-group ">
                <label>
                  <span v-text="$t('messages.allProducts')"></span><br>
                  <small class="text-muted" v-text="$t('informational.form.shippingDiscount.allStore')"></small>
                </label>
                <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                  <select class="custom-select" :class=" { 'is-invalid ':errors.length > 0 } "
                    v-model="newDiscount.toAllStore" @change="newDiscount.exclude = null; fnResetProductsList() ">
                    <option :value="null" selected v-text=" $t('general.form.select') ">
                    </option>
                    <option :value="true" selected v-text=" $t('general.button.yes') ">
                    </option>
                    <option :value="false" selected v-text=" $t('general.button.no') ">
                    </option>
                  </select>
                  <div class="input-group-append">
                    <label class="input-group-text">
                      <i class="fa fa-list"></i> </label>
                  </div>
                </div>
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </div>
            </v-validation>
          </div>

          <div class="col-12 col-lg-6 animated fadeIn" v-show="newDiscount.toAllStore">
            <v-validation :rules=" newDiscount.toAllStore ? 'required': '' " v-slot="{ errors }"
              :name=" $t('general.form.exclude') ">
              <div class="form-group ">
                <label>
                  <span v-text="$t('general.form.exclude')"></span><br>
                  <small class="text-muted" v-text="$t('informational.form.shippingDiscount.excludeProducts')"></small>
                </label>
                <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                  <select class="custom-select" :class=" { 'is-invalid ':errors.length > 0 } "
                    v-model="newDiscount.exclude" @change=" fnResetProductsList() ">
                    <option :value="null" selected v-text=" $t('general.form.select') ">
                    </option>
                    <option :value="true" selected v-text=" $t('general.button.yes') ">
                    </option>
                    <option :value="false" selected v-text=" $t('general.button.no') ">
                    </option>
                  </select>
                  <div class="input-group-append">
                    <label class="input-group-text">
                      <i class="fa fa-tasks"></i> </label>
                  </div>
                </div>
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </div>
            </v-validation>
          </div>
        </div>

        <hr class="mt-1">

        <div class="row mb-3" v-if="newDiscount.storeId">

          <div class="col-12 " v-if=" newDiscount.toAllStore === false">
            <p class="mb-1">
              <strong class="f-w-500" v-text="$t('general.form.products')"> </strong> <br>
              <small v-text="$t('informational.form.productsDiscount.productsList')"> </small>
            </p>
            <v-validation rules="required" v-slot="{ errors }" :name="$t('general.form.productsList') ">
              <ProductsList v-bind:storeId="newDiscount.storeId" ref="productsListSearch"
                v-bind:discountProductsList="newDiscount.productsList"
                @set-products=" productsList => {newDiscount.products = productsList}  " />
              <div class="form-group m-0">
                <input type="checkbox" class="input-group is-invalid " hidden v-model="newDiscount.products">
                <div class=" bg-danger p-1 invalid-feedback mt-0" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </div>
            </v-validation>
          </div>

          <div class="col-12 " v-if=" newDiscount.toAllStore === true && newDiscount.exclude ">
            <p class="mb-1">
              <strong class="f-w-500" v-text="$t('general.form.products')"> </strong> <br>
              <small v-text="$t('informational.form.productsDiscount.excludeProducts')"> </small>
            </p>
            <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.productsListExcluded') ">
              <ProductsList v-bind:storeId="newDiscount.storeId" ref="productsListExcludeSearch"
                v-bind:discountProductsList="newDiscount.productsListExcluded"
                @set-products=" productsList => {newDiscount.excludeProducts = productsList}  " />
              <div class="form-group m-0">
                <input type="checkbox" class="input-group is-invalid " hidden
                  v-model="newDiscount.excludeProducts">
                <div class=" bg-danger p-1 invalid-feedback mt-0" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </div>
            </v-validation>
          </div>

        </div>

        <div class="row">
          <div class="col-12">
            <div>
              <p class="m-0">
                <strong class="f-w-500" v-text="$t('general.form.availabilityDate')"> </strong>
              </p>
              <div v-if="newDiscount.applyDates">
                <p>
                  <small>
                    <span v-text="$t('messages.choseDates')+ ' '"></span>
                    <a class="text-info cursor-pointer" @click="newDiscount.applyDates = !newDiscount.applyDates"
                      v-text=" $t('general.button.hideForm') "></a>.
                  </small>
                </p>
                <FormaDates @set-dates=" (dates)=>{ newDiscount.dates = dates } " :disabledDates="newDiscount.disabledDates"
                  :initialDate="newDiscount.dates.initial" :finishDate="newDiscount.dates.finish" />
              </div>
              <p v-else>
                <small>
                  <span v-html="$t('messages.discountAvailable') + ' '"></span>
                  <a @click="newDiscount.applyDates = !newDiscount.applyDates"
                    class="text-lowercase text-info cursor-pointer" v-text=" $t('general.button.here') "></a>.
                </small>
              </p>
            </div>
          </div>

        </div>
        <input type="submit" hidden>
      </v-observer>

      <template v-slot:modal-footer>
        <p class="text-right">
          <button class="btn" v-text="$t('general.button.close')" @click="changeModal({ products: false })"></button>
          <button class="btn btn-success" :disabled="loading.newDiscount" @click="fnValidateDiscount()">
            <span v-text=" newDiscount.id ? $t('general.button.update') : $t('general.button.create') "
              v-if=" !loading.newDiscount "></span>
            <i class="fa fa-spinner fa-spin " v-else></i>
          </button>
        </p>
      </template>

    </b-modal>
  </div>
</template>

<script>
  import {
    mapState,
    mapActions,
    mapMutations,
    mapGetters
  } from 'vuex';
  import FormaDates from "@/components/FormDates.vue";

  export default {
    components: {
      FormaDates,
      ProductsList: () => import("./discounts-product-list"),
    },
    data() {
      return {
        productsSelect: [],
      }
    },
    computed: {
      ...mapState('discounts', ['modal', 'loading', 'newDiscount', 'storesList', 'productsList']),
    },
    watch: {
      productsSelect() {
        this.newDiscount.products = [];
        this.productsSelect.map(productId => {
          this.newDiscount.products.push({
            id: productId,
          })
        })
      }
    },
    methods: {
      ...mapActions('discounts', ['fnApiCreateProductsDiscount']),
      ...mapMutations('discounts', ['resetDiscount', 'changeModal']),
      fnResetProductsList() {
        this.$refs['productsListSearch'] ? this.$refs['productsListSearch'].fnResetData() : false;
        this.$refs['productsListExcludeSearch'] ? this.$refs['productsListExcludeSearch'].fnResetData() : false;
      },
      async fnValidateDiscount() {
        if (await this.$refs['formProductsDiscount'].validate()) {
          this.fnApiCreateProductsDiscount(this.newDiscount);
        } else {}
      },
    },
    mounted() {},
  }
</script>

<style>
</style>